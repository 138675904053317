<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header v-bind:title="$t('comingUpNext')"
                wide />
    <v-container>
      <v-row>
        <v-col>
          <public-occasion-schedule-card v-bind:schedule="schedule" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import PublicOccasionScheduleCard from '@/components/PublicOccasionScheduleCard.vue'

  export default {
    components: {
      PublicOccasionScheduleCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        schedule: 'base/schedule'
      })
    },
    async created() {
      this.loading = true

      await this.loadSchedule()

      this.loading = false
    },
    data: () => ({
      loading: false,
      occasions: []
    }),
    methods: {
      ...mapActions({
        loadSchedule: 'base/loadSchedule'
      })
    },
    name: 'Home'
  }
</script>

<i18n>
  {
    "de": {
      "comingUpNext": "Demnächst…"
    },
    "en": {
      "comingUpNext": "Coming up next…"
    }
  }
</i18n>