<template>
  <div>
    <v-card outlined
            rounded="xl">
      <v-toolbar color="secondary"
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <broadcaster-details ref="broadcasterDetails"
                           v-bind:broadcaster="broadcaster" />
    </v-card>
  </div>
</template>

<script>
  import BroadcasterDetails from '@/components/administration/BroadcasterDetails'

  export default {
    components: {
      BroadcasterDetails
    },
    name: 'BroadcasterDetailsCard',
    methods: {
      validate() {
        return this.$refs.broadcasterDetails.validate()
      }
    },
    props: {
      broadcaster: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>