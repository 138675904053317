<template>
  <diV>
    <broadcaster-add-dialog ref="broadcasterAddDialog"
                            v-model="broadcasterAddDialog" />

    <v-autocomplete chips
                    deletable-chips
                    hide-details="auto"
                    hide-selected
                    multiple
                    single-line
                    v-bind:clearable="clearable"
                    v-bind:disabled="disabled"
                    v-bind:filled="filled"
                    v-bind:items="broadcasters | items({ text: 'name', value: 'id' })"
                    v-bind:label="label"
                    v-bind:prefix="prefix"
                    v-bind:rounded="rounded"
                    v-bind:rules="rules"
                    v-bind:value="(value || []).map(v => v.broadcasterId)"
                    v-on:input="$emit('input', $event.map(v => ({ broadcasterId: v })))">
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('theBroadcasterYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewBroadcasters') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon
                   v-on:click="addBroadcaster">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-autocomplete>
  </diV>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BroadcasterAddDialog from '@/components/administration/BroadcasterAddDialog'

  export default {
    components: {
      BroadcasterAddDialog
    },
    computed: {
      ...mapGetters({
        broadcasters: 'base/broadcasters'
      })
    },
    data: () => ({
      broadcasterAddDialog: false
    }),
    name: 'BroadcasterSelect',
    methods: {
      async addBroadcaster() {
        await this.$refs.broadcasterAddDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.createBroadcaster({ broadcaster: r }) // TODO: Move to view

          this.$emit('input', [ ...this.value, { broadcasterId: r.id } ])

          this.loading = false
        })
      },
      ...mapActions({
        createBroadcaster: 'base/createBroadcaster'
      })
    },
    props: {
      clearable: Boolean,
      disabled: Boolean,
      filled: Boolean,
      label: String,
      prefix: String,
      rounded: Boolean,
      rules: Array,
      value: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "theBroadcasterYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewBroadcasters": "Die gesuchte Streamer:in befindet sich noch nicht in deiner Liste. Mit + kannst du neue Streamer:innen hinzufügen."
    },
    "en": {
      "theBroadcasterYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewBroadcasters": "The broadcaster you are looking for is not yet in your list. With + you can add new broadcasters."
    }
  }
</i18n>