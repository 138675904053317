<template>
  <v-list dense
          nav>
    <v-list-item exact
                 v-bind:to="{ name: 'administration-dashboard' }">
      <v-list-item-icon>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dashboard') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-occasions' }">
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('occasions') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-broadcasters' }">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('broadcasters') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'administration-categories' }">
      <v-list-item-icon>
        <v-icon>mdi-tag</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('categories') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'AdministrationNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "broadcasters": "Streamer:innen",
      "categories": "Kategorien",
      "dashboard": "Dashboard",
      "occasions": "Ereignisse"
    },
    "en": {
      "broadcasters": "Broadcasters",
      "categories": "Categories",
      "dashboard": "Dashboard",
      "occasions": "Occasions"
    }
  }
</i18n>