<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-toolbar color="primary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('addNewBroadcaster') }}</v-toolbar-title>
        </v-toolbar>
        <broadcaster-details ref="broadcasterDetails"
                             v-bind:broadcaster="broadcaster" />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                 text
                 v-on:click="confirm">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import BroadcasterDetails from '@/components/administration/BroadcasterDetails'

  export default {
    components: {
      BroadcasterDetails
    },
    data: () => ({
      broadcaster: {},
      resolve: null
    }),
    name: 'BroadcasterDetailsCard',
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.validate()) {
          return
        }

        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.broadcaster)
      },
      async open(resolve) {
        this.resolve = resolve
        this.broadcaster = {}
        this.$emit('input', true)
      },
      validate() {
        return this.$refs.broadcasterDetails.validate()
      }
    },
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "addNewBroadcaster": "Neue Streamer:in hinzufügen",
      "cancel": "Abbrechen",
      "details": "Details",
      "save": "Speichern"
    },
    "en": {
      "addNewBroadcaster": "Add new broadcaster",
      "cancel": "Cancel",
      "details": "Details",
      "save": "Save"
    }
  }
</i18n>