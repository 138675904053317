<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newBroadcaster')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-broadcasters' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="saveBroadcaster" />
    </top-header>
    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <broadcaster-details-card ref="broadcasterDetailsCard"
                                    v-bind:broadcaster="broadcaster" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import BroadcasterDetailsCard from '@/components/administration/BroadcasterDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      BroadcasterDetailsCard,
      HeaderButton,
      TopHeader
    },
    data: () => ({
      broadcaster: {},
      validationSnackbar: false
    }),
    methods: {
      async saveBroadcaster() {
        if (!this.$refs.broadcasterDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.createBroadcaster({ broadcaster: this.broadcaster })

        this.$router.push({ name: 'administration-broadcasters' })
      },
      ...mapActions({
        createBroadcaster: 'base/createBroadcaster'
      })
    },
    name: 'BroadcasterAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newBroadcaster": "Neue Streamer:in",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die deine Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newBroadcaster": "New broadcaster",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>