<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        rounded
                        hide-details="auto"
                        v-bind:label="$t('name')"
                        v-bind:rules="[ rules.required ]"
                        v-model="broadcaster.name" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea filled
                      rounded
                      hide-details="auto"
                      v-bind:label="$t('description')"
                      v-model="broadcaster.description" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        rounded
                        hide-details="auto"
                        v-bind:label="$t('url')"
                        v-model="broadcaster.url" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <image-attachment-field accept="image/jpeg"
                                  v-bind:label="$t('image')"
                                  v-bind:max-size="2048"
                                  v-model="broadcaster.imageAttachmentId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import ImageAttachmentField from '@/components/common/ImageAttachmentField'

  export default {
    components: {
      ImageAttachmentField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'BroadcasterDetails',
    props: {
      broadcaster: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "description": "Beschreibung",
      "image": "Bild",
      "name": "Name",
      "url": "URL"
    },
    "en": {
      "description": "Description",
      "image": "Image",
      "name": "Name",
      "url": "URL"
    }
  }
</i18n>