<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <date-field filled
                      rounded
                      hide-details="auto"
                      v-bind:label="$t('date')"
                      v-bind:rules="[ rules.required ]"
                      v-model="occasion.date" />
        </v-col>
        <v-col>
          <time-field disable-picker
                      filled
                      rounded
                      hide-details="auto"
                      v-bind:label="$t('start')"
                      v-bind:rules="[ rules.required ]"
                      v-model="occasion.start" />
        </v-col>
        <v-col>
          <time-field disable-picker
                      filled
                      rounded
                      hide-details="auto"
                      v-bind:label="$t('end')"
                      v-bind:rules="[ rules.required ]"
                      v-model="occasion.end" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea filled
                      rounded
                      hide-details="auto"
                      v-bind:label="$t('description')"
                      v-model="occasion.description" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox class="mt-0"
                      hide-details="auto"
                      v-bind:label="$t('specialEvent')"
                      v-model="occasion.isSpecialEvent" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <category-select filled
                           rounded
                           v-bind:label="$t('category')"
                           v-bind:rules="[ rules.required ]"
                           v-model="occasion.categoryId" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete clearable
                          filled
                          rounded
                          hide-details="auto"
                          v-bind:items="broadcasters | items({ text: 'name', value: 'id' })"
                          v-bind:label="$t('channel')"
                          v-model="occasion.activeBroadcasterId" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <broadcaster-select filled
                              rounded
                              v-bind:label="$t('guests')"
                              v-bind:prefix="$t('with')"
                              v-model="occasion.broadcasters" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CategorySelect from '@/components/administration/CategorySelect.vue'
  import BroadcasterSelect from '@/components/administration/BroadcasterSelect.vue'
  import DateField from '@/components/common/DateField.vue'
  import TimeField from '@/components/common/TimeField.vue'

  export default {
    components: {
      CategorySelect,
      BroadcasterSelect,
      DateField,
      TimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        broadcasters: 'base/broadcasters'
      })
    },
    data: () => ({
      guests: []
    }),
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'OccasionDetails',
    props: {
      occasion: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "category": "Kategorie",
      "channel": "Kanal (falls abweichend)",
      "date": "Datum",
      "description": "Beschreibung",
      "end": "Ende",
      "guests": "Gäst:innen",
      "specialEvent": "Special Event",
      "start": "Start",
      "with": "mit"
    },
    "en": {
      "category": "Category",
      "channel": "Channel (if different)",
      "date": "Date",
      "description": "Description",
      "end": "End",
      "guests": "Guests",
      "specialEvent": "Special event",
      "start": "Start",
      "with": "with"
    }
  }
</i18n>