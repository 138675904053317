<template>
  <div>
    <top-header v-bind:title="$t('occasions')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-occasion-add' }" />
    </top-header>
    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <occasion-table-card item-route-name="administration-occasion"
                               v-bind:occasions="occasions"
                               v-bind:loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import OccasionTableCard from '@/components/administration/OccasionTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      OccasionTableCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        occasions: 'base/occasions'
      })
    },
    async created() {
      this.loading = true

      await this.loadOccasions()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadOccasions: 'base/loadOccasions'
      })
    },
    name: 'Occasions'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "occasions": "Ereignisse"
    },
    "en": {
      "add": "Add",
      "occasions": "Occasions"
    }
  }
</i18n>