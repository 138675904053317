<template>
  <div>
    <v-card class="dropzone"
            height="192"
            outlined
            rounded="xl"
            v-on:click="upload"
            width="192">
      <v-img height="192"
             position="center top"
             width="192"
             v-bind:src="attachmentUri"
             v-if="value != null && !loading" />

      <v-container fill-height
                   fluid
                   v-else>
        <v-row>
          <v-col align="center">
            <v-progress-circular color="accent"
                                 indeterminate
                                 v-bind:active="loading"
                                 v-if="loading" />
            <p class="mb-0 text-caption text-center grey--text"
               v-else>
              {{ $t('uploadImage') }}
            </p>
          </v-col>
        </v-row>
      </v-container>

      <div class="overlay">
        <v-chip small>{{ label }}</v-chip>
      </div>
    </v-card>

    <p class="error--text mb-0 mt-1 mx-3 text-caption"
       v-if="maxSizeExceeded">
      {{ $t('theMaximumFileSizeOfWasExceeded') }}
    </p>

    <input class="hidden"
           ref="input"
           type="file"
           v-bind:accept="accept"
           v-on:change="change">
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      attachmentUri() {
        if (this.value == null) {
          return null
        }

        return `${this.attachmentBaseUrl}/${this.attachment(this.value)?.fileName}`
      },
      ...mapGetters({
        attachment: 'base/attachment',
        attachmentBaseUrl: 'base/attachmentBaseUrl'
      })
    },
    data: () => ({
      loading: false,
      maxSizeExceeded: false
    }),
    methods: {
      change(e) {
        const file = e.target.files[0]

        if (file == null) {
          return
        }

        if (file.size > this.maxSize * 1024) {
          this.maxSizeExceeded = true

          return
        }

        this.loading = true

        this.maxSizeExceeded = false

        const reader = new FileReader()

        reader.addEventListener("loadend", async e => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')

          const attachment = {
            contentType: file.type,
            data: base64String
          }

          await this.$store.dispatch('base/createAttachment', { attachment })

          this.loading = false

          this.$emit('input', attachment.id)
        })

        reader.readAsDataURL(file)
      },
      upload() {
        this.$refs.input.click()
      }
    },
    name: 'ImageAttachmentField',
    props: {
      accept: String,
      label: String,
      maxSize: Number,
      value: Number
    }
  }
</script>

<style scoped>
  .dropzone {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .overlay {
    top: 0.75rem;
    left: 0.75rem;
    position: absolute;
  }
</style>

<i18n>
  {
    "de": {
      "uploadImage": "Bild hochladen",
      "theMaximumFileSizeOfWasExceeded": "Die maximale Dateigröße von {maxSize} kB wurde überschritten."
    },
    "en": {
      "uploadImage": "Upload image",
      "theMaximumFileSizeOfWasExceeded": "The maximum file size of {maxSize} kB was exceeded."
    }
  }
</i18n>