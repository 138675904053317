import Vue from 'vue'

import AttachmentService from '@/api/base/attachment-service'
import BroadcasterService from '@/api/base/broadcaster-service'
import CategoryService from '@/api/base/category-service'
import IgdbGameService from '@/api/base/igdb-game-service'
import OccasionService from '@/api/base/occasion-service'
import OffDayService from '@/api/base/off-day-service'

import settings from '@/settings'

const attachmentService = new AttachmentService(settings.apiUri)
const broadcasterService = new BroadcasterService(settings.apiUri)
const categoryService = new CategoryService(settings.apiUri)
const igdbGameService = new IgdbGameService(settings.apiUri)
const occasionService = new OccasionService(settings.apiUri)
const offDayService = new OffDayService(settings.apiUri)

export default {
  actions: {
    // attachments
    // ===========
    async createAttachment({ commit, rootGetters }, { attachment }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attachmentService.create(attachment, { accessToken })

      attachment.id = result.id

      commit('createAttachment', { attachment: result })
    },
    async loadAttachments({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const attachments = await attachmentService.getAll({ accessToken })

      commit('setAttachments', { attachments })
    },

    // broadcasters
    // ============
    async createBroadcaster({ commit, rootGetters }, { broadcaster }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await broadcasterService.create(broadcaster, { accessToken })

      broadcaster.id = result.id

      commit('createBroadcaster', { broadcaster: result })
    },
    async deleteBroadcaster({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await broadcasterService.delete(id, { accessToken })

      if (result) {
        commit('deleteBroadcaster', { id })
      }
    },
    async loadBroadcaster({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const broadcaster = await broadcasterService.find(id, { accessToken })

      if (getters.broadcaster(id) != null) {
        commit('updateBroadcaster', { broadcaster })
      } else {
        commit('createBroadcaster', { broadcaster })
      }
    },
    async loadBroadcasters({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const broadcasters = await broadcasterService.getAll({ accessToken })

      commit('setBroadcasters', { broadcasters })
    },
    async updateBroadcaster({ commit, rootGetters }, { broadcaster }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await broadcasterService.update(broadcaster.id, broadcaster, { accessToken })

      if (result) {
        commit('updateBroadcaster', { broadcaster })
      }
    },

    // categories
    // ==========
    async createCategory({ commit, rootGetters }, { category }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.create(category, { accessToken })

      category.id = result.id

      commit('createCategory', { category: result })
    },
    async deleteCategory({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.delete(id, { accessToken })

      if (result) {
        commit('deleteCategory', { id })
      }
    },
    async loadCategory({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const category = await categoryService.find(id, { accessToken })

      if (getters.category(id) != null) {
        commit('updateCategory', { category })
      } else {
        commit('createCategory', { category })
      }
    },
    async loadCategories({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const categories = await categoryService.getAll({ accessToken })

      commit('setCategories', { categories })
    },
    async updateCategory({ commit, rootGetters }, { category }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await categoryService.update(category.id, category, { accessToken })

      if (result) {
        commit('updateCategory', { category })
      }
    },

    // igdb
    // ====
    async searchIgdbGame({ commit, rootGetters }, { query }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await igdbGameService.search(query, { accessToken })

      return result
    },

    // occasions
    // =========
    async createOccasion({ commit, rootGetters }, { occasion }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await occasionService.create(occasion, { accessToken })

      occasion.id = result.id

      commit('createOccasion', { occasion: result })
    },
    async deleteOccasion({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await occasionService.delete(id, { accessToken })

      if (result) {
        commit('deleteOccasion', { id })
      }
    },
    async loadOccasion({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const occasion = await occasionService.find(id, { accessToken })

      if (getters.occasion(id) != null) {
        commit('updateOccasion', { occasion })
      } else {
        commit('createOccasion', { occasion })
      }
    },
    async loadOccasions({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const occasions = await occasionService.getAll({ accessToken })

      commit('setOccasions', { occasions })
    },
    async loadSchedule({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const schedule = await occasionService.getSchedule({ accessToken })

      commit('setSchedule', { schedule })
    },
    async updateOccasion({ commit, rootGetters }, { occasion }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await occasionService.update(occasion.id, occasion, { accessToken })

      if (result) {
        commit('updateOccasion', { occasion })
      }
    },

    // offDays
    // =======
    async createOffDay({ commit, rootGetters }, { offDay }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await offDayService.create(offDay, { accessToken })

      commit('createOffDay', { offDay: result })
    },
    async deleteOffDay({ commit, rootGetters }, { date }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await offDayService.delete(date, { accessToken })

      if (result) {
        commit('deleteOffDay', { date })
      }
    },
    async loadOffDays({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const offDays = await offDayService.getAll({ accessToken })

      commit('setOffDays', { offDays })
    }
  },
  getters: {
    attachment: state => id => state.attachments.find(a => a.id === id),
    attachments: state => state.attachments,
    attachmentBaseUrl: state => settings.staticUri, // TODO: Move to local store
    broadcaster: state => id => state.broadcasters.find(b => b.id === id),
    broadcasterLabel: state => id => state.broadcasters.find(b => b.id === id)?.name,
    broadcasters: state => state.broadcasters,
    category: state => id => state.categories.find(c => c.id === id),
    categoryLabel: state => id => state.categories.find(c => c.id === id)?.name,
    categories: state => state.categories,
    occasion: state => id => state.occasions.find(o => o.id === id),
    occasions: state => state.occasions,
    offDay: state => date => state.offDays.find(o => o.date === date),
    offDays: state => state.offDays,
    schedule: state => state.schedule
  },
  mutations: {
    // attachments
    // ===========
    createAttachment(state, { attachment }) {
      state.attachments.push(attachment)
    },
    setAttachments(state, { attachments }) {
      state.attachments = attachments
    },

    // broadcasters
    // ============
    createBroadcaster(state, { broadcaster }) {
      state.broadcasters.push(broadcaster)
    },
    deleteBroadcaster(state, { id }) {
      const index = state.broadcasters.findIndex(b => b.id === id)

      if (index !== -1) {
        state.broadcasters.splice(index, 1)
      }
    },
    setBroadcasters(state, { broadcasters }) {
      state.broadcasters = broadcasters
    },
    updateBroadcaster(state, { broadcaster }) {
      const index = state.broadcasters.findIndex(b => b.id === broadcaster.id)

      Vue.set(state.broadcasters, index, broadcaster)
    },

    // categories
    // ==========
    createCategory(state, { category }) {
      state.categories.push(category)
    },
    deleteCategory(state, { id }) {
      const index = state.categories.findIndex(c => c.id === id)

      if (index !== -1) {
        state.categories.splice(index, 1)
      }
    },
    setCategories(state, { categories }) {
      state.categories = categories
    },
    updateCategory(state, { category }) {
      const index = state.categories.findIndex(c => c.id === category.id)

      Vue.set(state.categories, index, category)
    },

    // occasions
    // =========
    createOccasion(state, { occasion }) {
      state.occasions.push(occasion)
    },
    deleteOccasion(state, { id }) {
      const index = state.occasions.findIndex(o => o.id === id)

      if (index !== -1) {
        state.occasions.splice(index, 1)
      }
    },
    setOccasions(state, { occasions }) {
      state.occasions = occasions
    },
    setSchedule(state, { schedule }) {
      state.schedule = schedule
    },
    updateOccasion(state, { occasion }) {
      const index = state.occasions.findIndex(o => o.id === occasion.id)

      Vue.set(state.occasions, index, occasion)
    },

    // offDays
    // =======
    createOffDay(state, { offDay }) {
      state.offDays.push(offDay)
    },
    deleteOffDay(state, { date }) {
      const index = state.offDays.findIndex(o => o.date === date)

      if (index !== -1) {
        state.offDays.splice(index, 1)
      }
    },
    setOffDays(state, { offDays }) {
      state.offDays = offDays
    }
  },
  namespaced: true,
  state: {
    attachments: [],
    broadcasters: [],
    categories: [],
    occasions: [],
    schedule: [],
    offDays: []
  }
}