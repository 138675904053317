<template>
  <div>
    <v-slide-group show-arrows
                   v-model="model">
      <v-slide-item  v-bind:key="day.date"
                     v-for="day in schedule"
                     v-slot="{ active, toggle }">
        <occasion-day-card v-bind:day="day"
                           v-on:add-off-day="$emit('add-off-day', $event)"
                           v-on:delete-occasion="$emit('delete-occasion', $event)"
                           v-on:remove-off-day="$emit('remove-off-day', $event)" />
      </v-slide-item>
    </v-slide-group>

  </div>
</template>

<script>
  import OccasionDayCard from '@/components/administration/OccasionDayCard'

  export default {
    components: {
      OccasionDayCard
    },
    data: () => ({
      model: null
    }),
    name: 'OccassionScheduleCard',
    methods: {

    },
    props: {
      schedule: Array
    }
  }
</script>