<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newCategory')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-categories' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="saveCategory" />
    </top-header>
    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <category-details-card ref="categoryDetailsCard"
                                 v-bind:category="category"
                                 v-on:igdb:change="updateAttachmentFromIgdb" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CategoryDetailsCard from '@/components/administration/CategoryDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      CategoryDetailsCard,
      HeaderButton,
      TopHeader
    },
    data: () => ({
      category: {
        imageAttachmentId: null
      },
      validationSnackbar: false
    }),
    methods: {
      async saveCategory() {
        if (!this.$refs.categoryDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.createCategory({ category: this.category })

        this.$router.push({ name: 'administration-categories' })
      },
      async updateAttachmentFromIgdb(game) {
        return // TOOO
        const attachment = {
          contentType: 'image/jpeg',
          data: game.coverUrl
        }

        await this.$store.dispatch('base/createAttachment', { attachment })

        this.category.imageAttachmentId = attachment.id
      },
      ...mapActions({
        createCategory: 'base/createCategory'
      })
    },
    name: 'CategoryAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newCategory": "Neue Kategorie",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die deine Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newCategory": "New category",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>