<template>
  <div>
    <category-add-dialog ref="categoryAddDialog"
                         v-model="categoryAddDialog" />

    <v-autocomplete hide-details="auto"
                    v-bind:clearable="clearable"
                    v-bind:disabled="disabled"
                    v-bind:filled="filled"
                    v-bind:items="categories | items({ text: 'name', value: 'id' })"
                    v-bind:label="label"
                    v-bind:rounded="rounded"
                    v-bind:rules="rules"
                    v-bind:value="value"
                    v-on:input="$emit('input', $event)">
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('theCategoryYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewCategories') }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon
                   v-on:click="addCategory">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CategoryAddDialog from '@/components/administration/CategoryAddDialog'

  export default {
    components: {
      CategoryAddDialog
    },
    computed: {
      ...mapGetters({
        categories: 'base/categories'
      })
    },
    data: () => ({
      categoryAddDialog: false
    }),
    name: 'CategorySelect',
    methods: {
      async addCategory() {
        await this.$refs.categoryAddDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.createCategory({ category: r }) // TODO: Move to view

          this.$emit('input', r.id)

          this.loading = false
        })
      },
      ...mapActions({
        createCategory: 'base/createCategory'
      })
    },
    props: {
      clearable: Boolean,
      disabled: Boolean,
      filled: Boolean,
      label: String,
      rounded: Boolean,
      rules: Array,
      value: Number
    }
  }
</script>

<i18n>
  {
    "de": {
      "theCategoryYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewCategories": "Die gesuchte Kategorie befindet sich noch nicht in deiner Liste. Mit + kannst du neue Kategorien hinzufügen."
    },
    "en": {
      "theCategoryYouAreLookingForIsNotYetInYourListWithPlusYouCanAddNewCategories": "The category you are looking for is not yet in your list. With + you can add new categories."
    }
  }
</i18n>