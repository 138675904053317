<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false"
            v-bind:disabled="disablePicker">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind="attrs"
                      v-bind:clearable="clearable"
                      v-bind:disabled="disabled"
                      v-bind:filled="filled"
                      v-bind:flat="flat"
                      v-bind:hide-details="hideDetails"
                      v-bind:label="label"
                      v-bind:placeholder="formatString"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rounded="rounded"
                      v-bind:rules="rules"
                      v-bind:solo="solo"
                      v-mask="mask"
                      v-model="internalValue"
                      v-on="on"
                      v-on:change="changeTextField" />
      </template>
      <v-date-picker color="accent"
                     first-day-of-week="1"
                     header-color="primary"
                     v-bind:locale="$i18n.locale"
                     v-bind:value="internalDate != null ? internalDate.toISOString(true).substring(0, 10) : null"
                     v-on:change="changeDatePicker" />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      formatString() {
        return moment.localeData(this.$i18n.locale).longDateFormat('L')
      },
      mask() {
        return this.formatString.replace(/[DMYHm]/g, '#');
      }
    },
    created() {
      if (this.value != null) {
        this.updateInternalDate(moment(this.value))
      }
    },
    data: () => ({
      internalDate: null,
      internalValue: null
    }),
    methods: {
      updateInternalDate(date) {
        this.internalDate = date

        if (date == null) {
          this.internalValue = ''
        } else {
          this.internalValue = date.locale(this.$i18n.locale).format(this.formatString)
        }
      },
      changeDatePicker(value) {
        let date = moment(value)

        this.updateInternalDate(date)

        this.$emit('input', date.toISOString(true).substring(0, 10))
      },
      changeTextField(value) {
        let date = moment(value, this.formatString, this.$i18n.locale, true)

        if (!date.isValid()) {
          this.updateInternalDate(null)

          this.$emit('input', null)

          return
        }

        this.$emit('input', date.toISOString(true).substring(0, 10))
      }
    },
    name: 'DateField',
    props: {
      clearable: Boolean,
      disabled: Boolean,
      disablePicker: Boolean,
      filled: Boolean,
      flat: Boolean,
      hideDetails: String,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      rounded: Boolean,
      solo: Boolean,
      value: String
    },
    watch: {
      value(newValue, oldValue) {
        if (newValue == null) {
          this.updateInternalDate(null)
        } else {
          this.updateInternalDate(moment(newValue))
        }
      }
    }
  }
</script>

<!--<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind="attrs"
                      v-bind:clearable="clearable"
                      v-bind:disabled="disabled"
                      v-bind:filled="filled"
                      v-bind:flat="flat"
                      v-bind:hide-details="hideDetails"
                      v-bind:label="label"
                      v-bind:placeholder="formatString"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rounded="rounded"
                      v-bind:rules="rules"
                      v-bind:solo="solo"
                      v-mask="mask"
                      v-model="internalValue"
                      v-on="on"
                      v-on:change="changeTextField" />
      </template>
      <v-date-picker color="accent"
                     first-day-of-week="1"
                     header-color="primary"
                     v-bind:locale="$i18n.locale"
                     v-bind:value="internalDate != null ? internalDate.toISOString().substring(0, 10) : null"
                     v-on:change="changeDatePicker" />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      formatString() {
        return moment.localeData(this.$i18n.locale).longDateFormat('L')
      },
      mask() {
        return this.formatString.replace(/[DMYHm]/g, '#');
      }
    },
    created() {
      if (this.value != null) {
        this.updateInternalDate(moment(this.value))
      }
    },
    data: () => ({
      internalDate: null,
      internalValue: null
    }),
    methods: {
      updateInternalDate(date) {
        if (date != null && this.internalDate != null) {
          date.set({
            hours: this.internalDate.hours(),
            minutes: this.internalDate.minutes(),
            seconds: this.internalDate.seconds()
          })
        }

        this.internalDate = date

        if (date == null) {
          this.internalValue = ''
          this.$emit('input', null)
        } else {
          this.internalValue = date.locale(this.$i18n.locale).format(this.formatString)
          this.$emit('input', date.toISOString())
        }
      },
      changeDatePicker(value) {
        let date = moment(value)

        this.updateInternalDate(date)
      },
      changeTextField(value) {
        let date = moment(value, this.formatString, this.$i18n.locale, true)

        if (!date.isValid()) {
          this.updateInternalDate(null)
          return
        }

        this.updateInternalDate(date)
      }
    },
    name: 'DateField',
    props: {
      clearable: Boolean,
      disabled: Boolean,
      filled: Boolean,
      flat: Boolean,
      hideDetails: String,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      rounded: Boolean,
      solo: Boolean,
      value: String
    },
    watch: {
      value(newValue, oldValue) {
        if (newValue == null) {
          this.updateInternalDate(null)
        } else {
          this.updateInternalDate(moment(newValue))
        }
      }
    }
  }
</script>-->