<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirmation-label="$t('name')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisOccasionIrrevocably')"
                    v-bind:title="$t('deleteOccasion')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header v-bind:title="title">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-dashboard' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateOccasion" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteOccasion">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <occasion-details-card ref="occasionDetailsCard"
                                 v-bind:occasion="occasion"
                                 v-if="occasion != null" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import OccasionDetailsCard from '@/components/administration/OccasionDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      HeaderButton,
      OccasionDetailsCard,
      TopHeader
    },
    computed: {
      id() {
        return this.occasion?.id
      },
      title() {
        if (this.occasion != null) {
          return this.$options.filters.formatDate(`${this.occasion.date}T${this.occasion.start}`, 'L LT')
        }

        return this.$t('occasion')
      },
      ...mapGetters({
        //broadcasterLabel: 'base/broadcasterLabel',
        getOccasion: 'base/occasion'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      loading: false,
      occasion: null,
      validationSnackbar: false
    }),
    methods: {
      async deleteOccasion() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteOccasion({ id: this.occasion.id })

          this.loading = false

          this.$router.push({ name: 'administration-dashboard' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadBroadcasters()
        await this.loadCategories()
        await this.loadOccasion({ id })

        this.loading = false

        this.occasion = Object.assign({}, this.getOccasion(id))
      },
      async updateOccasion() {
        if (!this.$refs.occasionDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateOccasion({ occasion: this.occasion })

        this.loading = false

        this.$router.push({ name: 'administration-dashboard' })
      },
      ...mapActions({
        doDeleteOccasion: 'base/deleteOccasion',
        doUpdateOccasion: 'base/updateOccasion',
        loadBroadcasters: 'base/loadBroadcasters',
        loadCategories: 'base/loadCategories',
        loadOccasion: 'base/loadOccasion'
      })
    },
    name: 'Occasion'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisOccasionIrrevocably": "Sind Sie sicher, dass Sie dieses Ereignis unwiderruflich löschen möchten?",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteOccasion": "Ereignis löschen",
      "name": "Name",
      "occasion": "Ereignis",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die deine Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisOccasionIrrevocably": "Are you sure you want to delete this occasion irrevocably?",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteOccasion": "Delete occasion",
      "name": "Name",
      "occasion": "Occasion",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>