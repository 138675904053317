export default {
  actions: {},
  getters: {
    //salutation: state => value => state.salutations.find(s => s.value === value),
    //salutationLabel: state => value => state.salutations.find(s => s.value === value)?.label,
    //salutations: state => state.salutations
  },
  mutations: {},
  namespaced: true,
  state: {
    /*salutations: [
      {
        text: 'global.mr',
        value: 'mr'
      },
      {
        text: 'global.mrs',
        value: 'mrs'
      },
      {
        text: 'global.mx',
        value: 'mx'
      }
    ]*/
  }
}