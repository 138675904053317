<template>
  <div>
    <v-card class="mr-4"
            outlined
            rounded="xl"
            width="400">
      <v-toolbar color="secondary"
                 flat>
        <v-toolbar-title>{{ day.date | formatDate('dddd L') }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon
               v-on:click="toggleOffDay(day.date)">
          <v-icon>{{ isOffDay(day.date) ? 'mdi-sleep-off' : 'mdi-sleep' }}</v-icon>
        </v-btn>
      </v-toolbar>

      <template v-if="!isOffDay(day.date)">
        <template v-for="occasion in day.occasions">
          <v-card-text v-bind:key="`${occasion.id}-card_text`">
            <v-row align="center"
                   dense>
              <v-col cols="2" v-bind:class="{ 'deep-orange--text': isPast(occasion.date, occasion.end) }">
                {{ occasion.start | formatTime }}
              </v-col>
              <v-col>
                <p class="mb-0"><strong>{{ occasion.category.name }}</strong></p>
                <p class="mb-0" v-if="occasion.broadcasters != null && occasion.broadcasters.length !== 0">{{ $t('with', { value: $options.filters.broadcastersText(occasion.broadcasters) }) }}</p>
              </v-col>
              <v-col cols="auto">
                <v-btn icon
                      v-bind:to="{ name: 'administration-occasion', params: { id: occasion.id } }">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <delete-confirmation-button v-bind:item="occasion"
                                            v-on:delete="$emit('delete-occasion', $event.id)" />
              </v-col>
              <v-col cols="auto">
                <v-avatar v-if="occasion.category.imageAttachment != null">
                  <img v-bind:alt="occasion.category.name"
                      v-bind:src="`${staticUri}/${occasion.category.imageAttachment.fileName}`">
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider v-bind:key="`${occasion.id}-divider`" />
        </template>

        <v-card-text class="text-center">
          <v-btn color="accent"
                depressed
                rounded
                v-bind:to="{ name: 'administration-occasion-add', query: { date: day.date } }">
            {{ $t('addOccasion') }}
          </v-btn>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text>
          <p class="my-2 text-caption text-center grey--text">
              {{ $t('dayOff') }}
          </p>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import moment from 'moment'

  import settings from '@/settings'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      staticUri() {
        return settings.staticUri
      },
      ...mapGetters({
        offDays: 'base/offDays'
      })
    },
    name: 'OccassionDayCard',
    methods: {
      isOffDay(date) {
        return this.offDays.some(o => o.date === date)
      },
      isPast(date, time) {
        return moment(`${date}T${time}`).isBefore(moment())
      },
      toggleOffDay(date) {
        if (this.isOffDay(date)) {
          this.$emit('remove-off-day', date)
        } else {
          this.$emit('add-off-day', date)
        }
      }
    },
    props: {
      day: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "addOccasion": "Ereignis hinzufügen",
      "dayOff": "Freier Tag",
      "with": "mit {value}"
    },
    "en": {
      "addOccasion": "Add occasion",
      "dayOff": "Day off",
      "with": "with {value}"
    }
  }
</i18n>