var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] },"headers":_vm.headers,"items":_vm.filteredOccasions,"items-per-page":50,"loading":_vm.loading,"options":{ sortBy: [ 'date', 'start' ], sortDesc: [ true, true ] }},on:{"click:row":_vm.goToItem},scopedSlots:_vm._u([{key:"item.broadcasters",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("broadcastersText")(item.broadcasters))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date,'L'))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.end))+" ")]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.start))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }