<template>
  <v-app>
    <v-navigation-drawer app
                         clipped
                         color="secondary"
                         v-bind:expand-on-hover="$vuetify.breakpoint.lgAndUp"
                         v-bind:mini-variant.sync="miniVariant"
                         v-if="hasNavigation"
                         v-model="navigationDrawer">
      <router-view name="navigation" />
    </v-navigation-drawer>

    <v-app-bar app
               color="primary"
               dark
               flat
               v-bind:clipped-left="hasNavigation">
      <v-app-bar-nav-icon v-if="hasNavigation && $vuetify.breakpoint.mdAndDown"
                          v-on:click="navigationDrawer = !navigationDrawer" />

      <v-toolbar-title>
        Streamplan
      </v-toolbar-title>

      <v-spacer />

      <v-btn icon
             v-on:click="toggleDarkTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <locale-block v-bind:locales="locales"
                    v-on:set-locale="setLocale" />

      <account-block v-bind:display-name="displayName"
                     v-bind:email="email"
                     v-bind:is-authenticated="isAuthenticated"
                     v-bind:picture="picture"
                     v-on:sign-in="signIn"
                     v-on:sign-out="signOut">
        <template v-if="hasRole('StreamScheduleAdministrator')">
          <v-list-item v-bind:to="{ name: 'administration-dashboard' }">
            <v-list-item-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t('administration') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />
        </template>
      </account-block>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import locales from '@/locales.json'

  import AccountBlock from '@/components/common/AccountBlock'
  import LocaleBlock from '@/components/common/LocaleBlock'

  export default {
    components: {
      AccountBlock,
      LocaleBlock
    },
    computed: {
      hasNavigation() {
        const matched = this.$route.matched

        if (!matched[0] || !matched[0].meta) return false

        return matched[0].meta.hasNavigation
      },
      locales() {
        return locales
      },
      ...mapGetters({
        displayName: 'auth/displayName',
        email: 'auth/email',
        hasRole: 'auth/hasRole',
        isAuthenticated: 'auth/isAuthenticated',
        picture: 'auth/picture'
      })
    },
    data: () => ({
      miniVariant: null,
      navigationDrawer: null
    }),
    methods: {
      updateUi() {
        this.miniVariant = this.$vuetify.breakpoint.lgAndUp
        this.navigationDrawer = this.$vuetify.breakpoint.lgAndUp
      },
      setLocale({ localeId }) {
        this.$root.$i18n.locale = localeId
      },
      toggleDarkTheme() {
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      },
      ...mapActions({
        signIn: 'auth/signIn',
        signOut: 'auth/signOut'
      })
    },
    mounted() {
      this.updateUi()
    },
    name: 'App',
    watch: {
      '$vuetify.breakpoint.lgAndUp'() {
        this.updateUi()
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "administration": "Administration"
    },
    "en": {
      "administration": "Administration"
    }
  }
</i18n>