<template>
  <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 10, 30, 50, 100, -1 ] }"
                v-bind:headers="headers"
                v-bind:items="filteredOccasions"
                v-bind:items-per-page="50"
                v-bind:loading="loading"
                v-bind:options="{ sortBy: [ 'date', 'start' ], sortDesc: [ true, true ] }"
                v-on:click:row="goToItem">
    <template v-slot:[`item.broadcasters`]="{ item }">
      {{ item.broadcasters | broadcastersText }}
    </template>
    <template v-slot:[`item.date`]="{ item }">
      {{ item.date | formatDate('L') }}
    </template>
    <template v-slot:[`item.end`]="{ item }">
      {{ item.end | formatTime }}
    </template>
    <template v-slot:[`item.start`]="{ item }">
      {{ item.start | formatTime }}
    </template>
  </v-data-table>
</template>

<script>
  export default {
    computed: {
      filteredOccasions() {
        let filteredOccasions = this.occasions

        if (this.search != null && this.search.length !== 0) {
          filteredOccasions = filteredOccasions.filter(f => `${f.id} ${f.category?.name}`.toLowerCase().includes(this.search.toLowerCase()) || f.broadcasters.some(b => b.broadcaster.name.toLowerCase().includes(this.search.toLowerCase())))
        }

        return filteredOccasions
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('date'),
            value: 'date'
          },
          {
            text: this.$t('start'),
            value: 'start'
          },
          {
            text: this.$t('end'),
            value: 'end'
          },
          {
            text: this.$t('category'),
            value: 'category.name'
          },
          {
            text: this.$t('guests'),
            value: 'broadcasters'
          }
        ]
      }
    },
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'OccasionTable',
    props: {
      itemRouteName: String,
      loading: Boolean,
      occasions: Array,
      search: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "category": "Kategorie",
      "date": "Datum",
      "end": "Ende",
      "guests": "Gäst:innen",
      "number": "Nr.",
      "start": "Start"
    },
    "en": {
      "category": "Category",
      "date": "Date",
      "end": "End",
      "guests": "Guests",
      "number": "Number",
      "start": "Start"
    }
  }
</i18n>