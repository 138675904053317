<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false"
            v-bind:disabled="disablePicker">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field placeholder="HH:mm"
                      v-bind="attrs"
                      v-bind:append-icon="appendInnerIcon"
                      v-bind:clearable="clearable"
                      v-bind:disabled="disabled"
                      v-bind:filled="filled"
                      v-bind:flat="flat"
                      v-bind:hide-details="hideDetails"
                      v-bind:label="label"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rounded="rounded"
                      v-bind:rules="rules"
                      v-bind:solo="solo"
                      v-mask="'##:##'"
                      v-bind:value="internalTextValue"
                      v-on="on"
                      v-on:change="change" />
      </template>
      <v-time-picker color="accent"
                     header-color="primary"
                     format="24hr"
                     v-bind:locale="$i18n.locale"
                     v-bind:value="internalValue"
                     v-on:change="change" />
    </v-menu>
  </div>
</template>

<script>
  export default {
    computed: {
      internalTextValue() {
        return this.value?.substring(0, 5) ?? null
      },
      internalValue() {
        return this.value?.substring(0, 5) ?? '00:00'
      }
    },
    methods: {
      change(value) {
        this.$emit('input', `${value}:00`)
      }
    },
    name: 'TimeField',
    props: {
      appendInnerIcon: String,
      clearable: Boolean,
      disabled: Boolean,
      disablePicker: Boolean,
      filled: Boolean,
      flat: Boolean,
      hideDetails: String,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      rounded: Boolean,
      solo: Boolean,
      value: String
    }
  }
</script>