import moment from 'moment'
import Vue from 'vue'

import i18n from './plugins/i18n'

Vue.filter('broadcastersText', (value, defaultValue) => {
  defaultValue = defaultValue || ''

  if (value == null || value.length === 0) {
    return defaultValue
  }

  const formatter = new Intl.ListFormat(i18n.locale, { style: 'long', type: 'conjunction' });

  return formatter.format(value.map(b => b.broadcaster.name))
})

Vue.filter('defaultValue', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue
  }

  return value
})

Vue.filter('defaultPropertyValue', (value, property, defaultValue) => {
  if (value == null) {
    return defaultValue
  }

  if (value[property] == null || value[property].length === 0) {
    return defaultValue
  }

  return value[property]
})

Vue.filter('formatDate', (value, format, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).format(format ?? 'LL')
})

Vue.filter('formatTime', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return value.substring(0, 5)
})

Vue.filter('fromNow', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).fromNow()
})

Vue.filter('items', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: v[options.text],
    value: v[options.value]
  }))
})

Vue.filter('vueI18nItems', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: i18n.t(v[options.text]),
    value: v[options.value]
  }))
})