<template>
  <div>
    <top-header v-bind:title="$t('categories')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-category-add' }" />
    </top-header>
    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <category-table-card item-route-name="administration-category"
                               v-bind:loading="loading"
                               v-bind:categories="categories" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CategoryTableCard from '@/components/administration/CategoryTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      CategoryTableCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        categories: 'base/categories'
      })
    },
    async created() {
      this.loading = true

      await this.loadCategories()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadCategories: 'base/loadCategories'
      })
    },
    name: 'Categories'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "categories": "Kategorien"
    },
    "en": {
      "add": "Add",
      "categories": "Categories"
    }
  }
</i18n>