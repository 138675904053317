<template>
  <v-card-text>
    <h1 class="mb-4 text-center text-h6">{{ value | formatDate('MMMM YYYY') }}</h1>
    <v-sheet height="600">
      <v-calendar ref="calendar"
                  type="month"
                  v-bind:events="events"
                  v-bind:locale="$i18n.locale"
                  v-model="value"
                  v-on:click:day="goToDay($event.date)"
                  v-on:click:event="goToItem($event.event)" />
    </v-sheet>
  </v-card-text>
</template>

<script>
  export default {
    computed: {
      events() {
        return this.filteredOccasions.map(f => ({
          end: `${f.date}T${f.end}`,
          id: f.id,
          name: f.category?.name,
          start: `${f.date}T${f.start}`,
          color: f.isSpecialEvent ? 'primary' : 'accent'
        }))
      },
      filteredOccasions() {
        let filteredOccasions = this.occasions

        if (this.search != null && this.search.length !== 0) {
          filteredOccasions = filteredOccasions.filter(f => `${f.id} ${f.category?.name}`.toLowerCase().includes(this.search.toLowerCase()) || f.broadcasters.some(b => b.broadcaster.name.toLowerCase().includes(this.search.toLowerCase())))
        }

        return filteredOccasions
      },
    },
    created() {
      this.value = new Date()
    },
    data: () => ({
      value: ''
    }),
    methods: {
      goToDay(date) {
        this.$router.push({ name: 'administration-occasion-add', query: { date } })
      },
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      },
      next() {
        this.$refs.calendar.next()
      },
      previous() {
        this.$refs.calendar.prev()
      }
    },
    name: 'OccasionCalendar',
    props: {
      itemRouteName: String,
      loading: Boolean,
      occasions: Array,
      search: String
    }
  }
</script>

<i18n>
  {
    "de": {

    },
    "en": {

    }
  }
</i18n>