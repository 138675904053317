<template>
  <div>
    <v-card class="mr-4"
            outlined
            rounded="xl"
            width="400">
      <v-toolbar color="secondary"
                 flat>
        <v-toolbar-title>{{ day.date | formatDate('dddd L') }}</v-toolbar-title>
      </v-toolbar>
      <template v-if="!day.isOffDay">
        <template v-for="(occasion, index) in day.occasions">
          <v-card-text v-bind:key="`${occasion.id}-card_text`">
            <v-row align="center"
                   dense>
              <v-col cols="2" v-bind:class="{ 'deep-orange--text': isPast(occasion.date, occasion.end) }">
                {{ occasion.start | formatTime }}
              </v-col>
              <v-col>
                <p class="mb-0"><strong>{{ occasion.category.name }}</strong></p>
                <p class="mb-0" v-if="occasion.broadcasters != null && occasion.broadcasters.length !== 0">{{ $t('with', { value: $options.filters.broadcastersText(occasion.broadcasters) }) }}</p>
              </v-col>
              <v-col cols="auto">
                <v-avatar v-if="occasion.category.imageAttachment != null">
                  <img v-bind:alt="occasion.category.name"
                      v-bind:src="`${staticUri}/${occasion.category.imageAttachment.fileName}`">
                </v-avatar>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider v-bind:key="`${occasion.id}-divider`"
                    v-if="index !== day.occasions.length -1" />
        </template>
      </template>
      <template v-else>
        <v-card-text>
          <p class="my-2 text-caption text-center grey--text">
              {{ $t('dayOff') }}
          </p>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment'

  import settings from '@/settings'

  export default {
    computed: {
      staticUri() {
        return settings.staticUri
      }
    },
    name: 'PublicOccassionDayCard',
    methods: {
      isPast(date, time) {
        return moment(`${date}T${time}`).isBefore(moment())
      }
    },
    props: {
      day: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "dayOff": "Freier Tag",
      "with": "mit {value}"
    },
    "en": {
      "dayOff": "Day off",
      "with": "with {value}"
    }
  }
</i18n>