import Dashboard from '@/views/administration/Dashboard.vue'
import Broadcaster from '@/views/administration/Broadcaster.vue'
import BroadcasterAdd from '@/views/administration/BroadcasterAdd.vue'
import Broadcasters from '@/views/administration/Broadcasters.vue'
import Categories from '@/views/administration/Categories.vue'
import Category from '@/views/administration/Category.vue'
import CategoryAdd from '@/views/administration/CategoryAdd.vue'
import Occasion from '@/views/administration/Occasion.vue'
import OccasionAdd from '@/views/administration/OccasionAdd.vue'
import Occasions from '@/views/administration/Occasions.vue'
//import HistoryEvents from '@/views/administration/HistoryEvents.vue'
//import Products from '@/views/administration/Products.vue'
//import Segment from '@/views/administration/Segment.vue'
//import SegmentAdd from '@/views/administration/SegmentAdd.vue'
//import Segments from '@/views/administration/Segments.vue'
//import Shelf from '@/views/administration/Shelf.vue'
//import ShelfAdd from '@/views/administration/ShelfAdd.vue'
//import Shelves from '@/views/administration/Shelves.vue'
import AdministrationNavigation from '@/views/administration/navigation/AdministrationNavigation.vue'

export default [
  {
    components: {
      default: Dashboard,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-dashboard',
    path: '/administration'
  },
  {
    components: {
      default: Broadcasters,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-broadcasters',
    path: '/administration/broadcasters'
  },
  {
    components: {
      default: BroadcasterAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-broadcaster-add',
    path: '/administration/broadcasters/add'
  },
  {
    components: {
      default: Broadcaster,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-broadcaster',
    path: '/administration/broadcasters/:id'
  },
  {
    components: {
      default: Categories,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-categories',
    path: '/administration/categories'
  },
  {
    components: {
      default: CategoryAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-category-add',
    path: '/administration/categories/add'
  },
  {
    components: {
      default: Category,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-category',
    path: '/administration/categories/:id'
  },
  {
    components: {
      default: Occasions,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-occasions',
    path: '/administration/occasions'
  },
  {
    components: {
      default: OccasionAdd,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-occasion-add',
    path: '/administration/occasions/add'
  },
  {
    components: {
      default: Occasion,
      navigation: AdministrationNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('StreamScheduleAdministrator'),
      hasNavigation: true
    },
    name: 'administration-occasion',
    path: '/administration/occasions/:id'
  }
]