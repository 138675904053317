export default class IgdbGameService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUri}/igdb/game/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async search(query, options) {
    const response = await fetch(`${this.baseUri}/igdb/game/?query=${query}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }
}