<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirmation-label="$t('name')"
                    v-bind:confirmation-value="category | defaultPropertyValue('name', $t('name'))"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisCategoryIrrevocably')"
                    v-bind:title="$t('deleteCategory')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header v-bind:title="title">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-categories' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateCategory" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteCategory">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <category-details-card ref="categoryDetailsCard"
                                 v-bind:category="category"
                                 v-if="category != null"
                                 v-on:igdb:change="updateAttachmentFromIgdb" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CategoryDetailsCard from '@/components/administration/CategoryDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      CategoryDetailsCard,
      ConfirmDialog,
      HeaderButton,
      TopHeader
    },
    computed: {
      id() {
        return this.category?.id
      },
      title() {
        if (this.category != null) {
          return this.categoryLabel(this.id)
        }

        return this.$t('category')
      },
      ...mapGetters({
        categoryLabel: 'base/categoryLabel',
        getCategory: 'base/category'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      category: null,
      confirmDeleteDialog: false,
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async deleteCategory() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteCategory({ id: this.category.id })

          this.loading = false

          this.$router.push({ name: 'administration-categories' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadAttachments()
        await this.loadCategory({ id })

        this.loading = false

        this.category = Object.assign({}, this.getCategory(id))
      },
      async updateAttachmentFromIgdb(game) {
        return // TOOO
        const attachment = {
          contentType: 'image/jpeg',
          data: game.coverUrl
        }

        await this.$store.dispatch('base/createAttachment', { attachment })

        this.category.imageAttachmentId = attachment.id
      },
      async updateCategory() {
        if (!this.$refs.categoryDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateCategory({ category: this.category })

        this.loading = false

        this.$router.push({ name: 'administration-categories' })
      },
      ...mapActions({
        doDeleteCategory: 'base/deleteCategory',
        doUpdateCategory: 'base/updateCategory',
        loadAttachments: 'base/loadAttachments',
        loadCategory: 'base/loadCategory'
      })
    },
    name: 'Category'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisCategoryIrrevocably": "Sind Sie sicher, dass Sie diese Kategorie unwiderruflich löschen möchten?",
      "category": "Kategorie",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteCategory": "Kategorie löschen",
      "name": "Name",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die deine Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisCategoryIrrevocably": "Are you sure you want to delete this category irrevocably?",
      "category": "Category",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteCategory": "Delete category",
      "name": "Name",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>