<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-toolbar color="primary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('addNewCategory') }}</v-toolbar-title>
        </v-toolbar>
        <category-details ref="categoryDetails"
                          v-bind:category="category"
                          v-on:igdb:change="updateAttachmentFromIgdb" />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                 text
                 v-on:click="confirm">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import CategoryDetails from '@/components/administration/CategoryDetails'

  export default {
    components: {
      CategoryDetails
    },
    data: () => ({
      category: {
        imageAttachmentId: null
      },
      resolve: null
    }),
    name: 'CategoryDetailsCard',
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.validate()) {
          return
        }

        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.category)
      },
      async open(resolve) {
        this.resolve = resolve
        this.category = {}
        this.$emit('input', true)
      },
      async updateAttachmentFromIgdb(game) {
        return // TOOO
        const attachment = {
          contentType: 'image/jpeg',
          data: game.coverUrl
        }

        await this.$store.dispatch('base/createAttachment', { attachment })

        this.category.imageAttachmentId = attachment.id
      },
      validate() {
        return this.$refs.categoryDetails.validate()
      }
    },
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "addNewCategory": "Neue Kategorie hinzufügen",
      "cancel": "Abbrechen",
      "details": "Details",
      "save": "Speichern"
    },
    "en": {
      "addNewCategory": "Add new category",
      "cancel": "Cancel",
      "details": "Details",
      "save": "Save"
    }
  }
</i18n>