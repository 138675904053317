<template>
  <div>
    <v-combobox filled
                hide-details="auto"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                rounded
                v-bind:items="items"
                v-bind:label="label"
                v-bind:loading="loading"
                v-bind:rules="rules"
                v-bind:search-input.sync="search"
                v-model="model"
                v-on:input="update" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    computed: {

    },
    async created() {
      this.model = this.value
    },
    data: () => ({
      model: null,
      items: [],
      loading: false,
      search: null,
      lastRequestTime: 0
    }),
    methods: {
      update() {
        let value = this.model

        if (typeof value === 'object') {
          this.$emit('igdb:change', value)

          value = value.name
        }

        this.$emit('input', value)
      },
      ...mapActions({
        searchIgdbGame: 'base/searchIgdbGame'
      })
    },
    name: 'DateField',
    props: {
      label: String,
      rules: Array,
      value: String
    },
    watch: {
      async search(val) {
        if (val == null || val.length < 3) {
          return
        }

        let requestTime = new Date().valueOf()
        this.lastRequestTime = requestTime

        this.loading = true

        const result = await this.searchIgdbGame({ query: val })

        if (this.lastRequestTime <= requestTime) {
          this.items = [ ...this.items, ...result.filter(r => this.items.find(i => i.id === r.id) == null) ]
        }

        this.loading = false
      }
    }
  }
</script>