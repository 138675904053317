<template>
  <v-card outlined
          rounded="xl">
    <v-toolbar color="secondary"
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-btn icon
             v-if="showCalendarView"
             v-on:click="$refs.occasionCalendar.previous()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t('occasions') }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon
             v-if="showCalendarView"
             v-on:click="$refs.occasionCalendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn icon
             v-on:click="showCalendarView = !showCalendarView">
        <v-icon>{{ showCalendarView ? 'mdi-table' : 'mdi-calendar' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <occasion-calendar ref="occasionCalendar"
                       v-bind:item-route-name="itemRouteName"
                       v-bind:loading="loading"
                       v-bind:occasions="occasions"
                       v-bind:search="search"
                       v-if="showCalendarView"/>
    <occasion-table v-bind:item-route-name="itemRouteName"
                    v-bind:loading="loading"
                    v-bind:occasions="occasions"
                    v-bind:search="search"
                    v-else />
  </v-card>
</template>

<script>
  import OccasionCalendar from '@/components/administration/OccasionCalendar.vue'
  import OccasionTable from '@/components/administration/OccasionTable.vue'

  export default {
    components: {
      OccasionCalendar,
      OccasionTable
    },
    data: () => ({
      search: null,
      showCalendarView: true
    }),
    name: 'OccasionTableCard',
    props: {
      itemRouteName: String,
      loading: Boolean,
      occasions: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "category": "Kategorie",
      "date": "Datum",
      "end": "Ende",
      "guests": "Gäst:innen",
      "number": "Nr.",
      "occasions": "Ereignisse",
      "search": "Suche",
      "start": "Start"
    },
    "en": {
      "category": "Category",
      "date": "Date",
      "end": "End",
      "guests": "Guests",
      "number": "Number",
      "occasions": "Occasions",
      "search": "Search",
      "start": "Start"
    }
  }
</i18n>