<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header v-bind:title="$t('dashboard')"
                wide />
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-account"
                               v-bind:title="$t('broadcasters')"
                               v-bind:to="{ name: 'administration-broadcasters' }" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <icon-dashboard-card icon="mdi-tag"
                               v-bind:title="$t('categories')"
                               v-bind:to="{ name: 'administration-categories' }" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <occasion-schedule-card v-bind:schedule="schedule"
                                  v-on:add-off-day="addOffDay"
                                  v-on:delete-occasion="deleteOccasion"
                                  v-on:remove-off-day="removeOffDay" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import OccasionScheduleCard from '@/components/administration/OccasionScheduleCard.vue'
  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      IconDashboardCard,
      OccasionScheduleCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        schedule: 'base/schedule',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      this.loading = true

      await this.loadOffDays()
      await this.loadSchedule()

      this.loading = false
    },
    data: () => ({
      loading: false,
      occasions: []
    }),
    methods: {
      async addOffDay(date) {
        const offDay = {
          date: date
        }

        this.loading = true

        await this.createOffDay({ offDay })

        this.loading = false
      },
      async deleteOccasion(id) {
        this.loading = true

        await this.doDeleteOccasion({ id })
        await this.loadSchedule() // TODO: Delete Occassion from schedule directly

        this.loading = false
      },
      async removeOffDay(date) {
        this.loading = true

        await this.deleteOffDay({ date })

        this.loading = false
      },
      ...mapActions({
        createOffDay: 'base/createOffDay',
        deleteOffDay: 'base/deleteOffDay',
        doDeleteOccasion: 'base/deleteOccasion',
        loadOffDays: 'base/loadOffDays',
        loadSchedule: 'base/loadSchedule',
      })
    },
    name: 'Dashboard'
  }
</script>

<i18n>
  {
    "de": {
      "broadcasters": "Streamer:innen",
      "categories": "Kateogrien",
      "dashboard": "Dashboard"
    },
    "en": {
      "broadcasters": "Broadcasters",
      "categories": "Categories",
      "dashboard": "Dashboard"
    }
  }
</i18n>