<template>
  <div class="pt-2">
    <v-container v-bind:class="!!this.$slots['tabs'] ? 'pb-0' : null">
      <v-row>
        <v-col cols="auto"
               v-if="!!this.$slots['left-button']">
          <slot name="left-button" />
        </v-col>
        <v-spacer />
        <v-col cols="auto"
               v-if="!!this.$slots['right-button']">
          <slot name="right-button" />
        </v-col>
        <v-col cols="auto"
               v-if="!!this.$slots['menu']">
          <slot name="menu" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12"
               v-bind:xl="wide ? 12 : 8">
          <slot>
            <h1 class="display-1 font-weight-bold mx-4 pb-4 pt-2 primary--text">{{ title }}</h1>
          </slot>
        </v-col>
      </v-row>
      <slot name="tabs" />
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    props: {
      title: String,
      wide: Boolean
    }
  }
</script>