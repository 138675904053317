<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <igdb-game-autocomplete v-bind:label="$t('name')"
                                  v-bind:rules="[ rules.required ]"
                                  v-model="category.name"
                                  v-on:igdb:change="$emit('igdb:change', $event)" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <image-attachment-field accept="image/jpeg"
                                  v-bind:label="$t('image')"
                                  v-bind:max-size="2048"
                                  v-model="category.imageAttachmentId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import IgdbGameAutocomplete from '@/components/administration/IgdbGameAutocomplete'
  import ImageAttachmentField from '@/components/common/ImageAttachmentField'

  export default {
    components: {
      IgdbGameAutocomplete,
      ImageAttachmentField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CategoryDetails',
    props: {
      category: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "image": "Bild",
      "name": "Name"
    },
    "en": {
      "image": "Image",
      "name": "Name"
    }
  }
</i18n>