import Home from '@/views/Home.vue'

export default [
  {
    components: {
      default: Home
    },
    meta: {
      hasNavigation: false
    },
    name: 'home',
    path: '/'
  }
]