<template>
  <div>
    <v-slide-group show-arrows
                   v-model="model">
      <v-slide-item  v-bind:key="day.date"
                     v-for="day in schedule"
                     v-slot="{ active, toggle }">
        <public-occasion-day-card v-bind:day="day" />
      </v-slide-item>
    </v-slide-group>

  </div>
</template>

<script>
  import PublicOccasionDayCard from '@/components/PublicOccasionDayCard'

  export default {
    components: {
      PublicOccasionDayCard
    },
    data: () => ({
      model: null
    }),
    name: 'PublicOccassionScheduleCard',
    props: {
      schedule: Array
    }
  }
</script>